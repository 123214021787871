import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'; // Importando Link do react-router-dom
import logo from '../img/Marca - Link School-01 (1).png';
import './NavBar.css';

function NavBar() {
    const navbarStyle = {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
        width: '85%',
        margin: '0 auto',
        marginTop: '15px',
        borderRadius: '20px',
    };

    const linkStyle = {
        color: 'rgba(64, 63, 63, 1)',
        fontFamily: 'NeueMachine, sans-serif',
        fontWeight: '400',
        fontSize: '17px',
        marginTop: '5px',
    };

    const separator = {
        padding: '0 10px',
        lineHeight: '44px',
    };

    const brandStyle = {
        marginLeft: '20px', // Espaçamento à direita da imagem
    };

    return (
        <Navbar bg="rgba(241, 241, 241, 0.68)" variant="dark" expand="md" style={navbarStyle}>
            <Navbar.Brand as={Link} to="/" style={brandStyle}>
                <img
                    src={logo}
                    width="200px"
                    height="80px"
                    className="d-inline-block"
                    alt="Logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link as={Link} to="/#quem-somos" style={linkStyle}>Quem nós somos?</Nav.Link>
                    <span className="d-none d-lg-inline" style={separator}>|</span>
                    <Nav.Link as={Link} to="/cursos" style={linkStyle}>Cursos</Nav.Link>
                    <span className="d-none d-lg-inline" style={separator}>|</span>
                    <Nav.Link as={Link} to="/professores" style={linkStyle}>Professores</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;
