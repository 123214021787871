import React from "react";
import './CursoRockets.css';
import { Dropdown, Card, CardGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar';

function CursoRockets(){
    return(
        <>
            <NavBar />

             <div className="curso-content1">
                <div className="curso-header1">
                    <h1 className="curso-title1">Curso Rockets</h1>

                </div>
                <p className="curso-description1">
                Nosso treinamento especial para líderes e futuros líderes está de cara nova. Estamos muito animados para compartilhar e viver esse tempo de imersão nessa área com vocês! Reformulamos todo o processo, fluxo e didática para sermos intencionais e entregar um conteúdo pautado nos princípios bíblico da liderança de Jesus.

                </p>
                <p className="curso-description1">
                O curso será para líderes, futuros líderes da igreja e indicados pela liderança para exercer influência na igreja e em todas as esferas da sociedade. A inscrição será feita por meio de um formulário e após aprovação nossa equipe entrará em contato pelo seu whatsapp para concluir o processo.

                </p>
            </div>
            <div className="footer-line-curso">
                <div className="content-modulo">
                <h1 className="titulo-curso1">Módulos:</h1>
                <CardGroup className="modulos-group1">
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">1</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Modelo de liderança de Jesus</li>
                                    <li>Panorama bíblico e história da igreja</li>
                                    <li>A conquista da liderança</li>
                                    <li>A liderança pelo filtro da cruz</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">2</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Secular e sagrado</li>
                                    <li>Mordomia 1</li>
                                    <li>Mordomia 2</li>
                                    <li>Mordomia 3</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">3</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>O caráter do obreiro aprovado</li>
                                    <li>Como lidar com perfis espirituais</li>
                                    <li>Geradores de ambiente</li>
                                    <li>Resolução de conflitos</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">4</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Formação de líderes</li>
                                    <li>Influência e honra</li>
                                    <li>Liderança em tempos de crise</li>
                                    <li>Aconselhamento e discipulado</li>
                                    <li>Imagem pessoa, oratória e sermão</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
              
                </div>
                <div className="footer-line3">
                <div className="contact-info2">
                    <span className="ctt2">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                        </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );

}
export default CursoRockets;