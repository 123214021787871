import foto1 from '../img/IMG_8580.CR3.jpg';
import foto2 from '../img/IMG_8554.CR3.jpg';
import foto3 from '../img/IMG_8544.CR3.jpg';
import foto4 from '../img/IMG_8517.CR3 (1).jpg';
import foto5 from '../img/IMG_8511.CR3 (1).jpg';
import foto6 from '../img/IMG_1718 (3).jpg';
import React, { useState, useEffect } from 'react';
import './Carrosselmob.css';

export default function Carrosselmob() {
    const [activeIndex, setActiveIndex] = useState(0);

    // Função para lidar com o clique nas miniaturas
    const handleThumbClick = (index) => {
        setActiveIndex(index);
    };

    // Função para ir ao próximo slide
    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 6); // Avança para o próximo slide
    };

    // Função para ir ao slide anterior
    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + 6) % 6); // Volta para o slide anterior
    };

    // Função de autoplay
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 6); // Incrementa o índice, e volta para 0 após o último slide
        }, 3000); // Muda de slide a cada 3 segundos

        return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
    }, []);

    return (
        <>
            <div id="carouselExampleIndicators" className="carousel slide mt-4" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {[...Array(6)].map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={index}
                            className={activeIndex === index ? 'active' : ''}
                            aria-current={activeIndex === index ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                            onClick={() => handleThumbClick(index)} // Permite que as bolinhas também naveguem pelos slides
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
                        <img src={foto1} className="d-block w-100" alt="Imagem 1 da aula" />
                    </div>
                    <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
                        <img src={foto2} className="d-block w-100" alt="Imagem 2 da aula" />
                    </div>
                    <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
                        <img src={foto3} className="d-block w-100" alt="Imagem 3 da aula" />
                    </div>
                    <div className={`carousel-item ${activeIndex === 3 ? 'active' : ''}`}>
                        <img src={foto4} className="d-block w-100" alt="Imagem 4 da aula" />
                    </div>
                    <div className={`carousel-item ${activeIndex === 4 ? 'active' : ''}`}>
                        <img src={foto5} className="d-block w-100" alt="Imagem 5 da aula" />
                    </div>
                    <div className={`carousel-item ${activeIndex === 5 ? 'active' : ''}`}>
                        <img src={foto6} className="d-block w-100" alt="Imagem 6 da aula" />
                    </div>
                </div>
                <button
                    className="carousel-control-prev1"
                    type="button"
                    onClick={handlePrev} // Substituímos data-bs-target pelo nosso controle
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next1"
                    type="button"
                    onClick={handleNext} // Substituímos data-bs-target pelo nosso controle
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            {/* Miniaturas do Carrossel */}
            <div className="carousel-thumbnails mt-3 d-flex justify-content-center">
                <img
                    src={foto1}
                    className={`thumbnail ${activeIndex === 0 ? 'active' : ''}`}
                    alt="Miniatura 1"
                    onClick={() => handleThumbClick(0)}
                />
                <img
                    src={foto2}
                    className={`thumbnail ${activeIndex === 1 ? 'active' : ''}`}
                    alt="Miniatura 2"
                    onClick={() => handleThumbClick(1)}
                />
                <img
                    src={foto3}
                    className={`thumbnail ${activeIndex === 2 ? 'active' : ''}`}
                    alt="Miniatura 3"
                    onClick={() => handleThumbClick(2)}
                />
                <img
                    src={foto4}
                    className={`thumbnail ${activeIndex === 3 ? 'active' : ''}`}
                    alt="Miniatura 4"
                    onClick={() => handleThumbClick(3)}
                />
                <img
                    src={foto5}
                    className={`thumbnail ${activeIndex === 4 ? 'active' : ''}`}
                    alt="Miniatura 5"
                    onClick={() => handleThumbClick(4)}
                />
                <img
                    src={foto6}
                    className={`thumbnail ${activeIndex === 5 ? 'active' : ''}`}
                    alt="Miniatura 6"
                    onClick={() => handleThumbClick(5)}
                />
            </div>
        </>
    );
}
