import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './screens/home';
import Cursos from './screens/Cursos';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CursoBase from './screens/CursoBase';
import CursoNefesh from './screens/CursoNefesh';
import CursoShorts from'./screens/CursoShorts';
import Professores from './screens/Professores';
import CursoRockets from './screens/CursoRockets';
import ScrollToHash from './components/ScrollToHash';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <ScrollToHash />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/cursos' element={<Cursos />} />
        <Route path='/cursobase' element={<CursoBase />} />
        <Route path='/cursonefesh' element={<CursoNefesh />} />
        <Route path='/cursoshorts' element={<CursoShorts />} />
        <Route path='/professores' element={<Professores />} />
        <Route path='/cursorockets' element={<CursoRockets />} />

      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
