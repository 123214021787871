import React from "react";
import './CursoShorts.css';
import { Dropdown, Card, CardGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar';

function CursoShorts(){
    return(
        <>
             <NavBar />

             <div className="curso-content3">
                <div className="curso-header3">
                    <h1 className="curso-title3">Cursos Shorts</h1>

                </div>
                <p className="curso-description3">
                Os shorts são nossos novos cursos intensivos onde nos aprofundaremos em temas específicos de forma intencional, profunda e intensa com base na palavra de Deus!
                </p>
                <div className="curso-header12">
                    <h2 className="curso-subtitle">Shorts Família</h2>
                    <a class="btn btn-primary btn-matricula1" href="#" role="button">Matricule-se</a>

                </div>
                <p className="curso-description3">
                Nosso objetivo no curso é passar uma visão de famílias funcionais dentro da ótica de reino trazendo cura para lares e ajudando as futuras famílias a colocarem seus  alicerces no Senhor.
                A dinâmica do short família conta com as aulas,  grupos greis (compartilhamento) e aconselhamentos com professores da escola.
                </p>
                <div className="curso-header12">
                    <h2 className="curso-subtitle">Shorts Sobrenatural</h2>
                    <a class="btn btn-primary btn-matricula12" href="#" role="button">Matricule-se</a>

                </div>
                <p className="curso-description3">
                o Shorts Sobrenatural é para quem quer aprender a se mover no profético de maneira saudável e bíblica, se você deseja se aprofundar mais no tema esse é próximo passo para você.
                Durante o curso compartilharemos a parte teórica, mas também prática para que você saia dos shorts com esse conhecimento de como colocar o que foi ensinado no seu dia a dia de acordo com os princípios e ensinamentos de Deus.
                </p>
            </div>
            <div className="footer-line-nefesh3">
                <div className="content-nefesh3">
                    <h1 className="titulo-nefesh23">Pra quem é?</h1>
                    <ul className="pq-text3">
                        <li>Para você que quer aprender o modelo familiar do Senhor</li>
                        <li>Solteiros que buscam aprender a se relacionar de forma saudável</li>
                        <li>Futuros casais para entrar no casamento com bases</li>
                        <li>Casais que querem fortalecer seu casamento</li>
                    </ul>
                </div>
            </div>
            <div className="footer-line23">
                <div className="contact-info23">
                    <span className="ctt23">CONTATO</span>
                    <div className="contact-details23">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                            </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CursoShorts;