import foto1 from '../img/IMG_8580.CR3.jpg';
import foto2 from '../img/IMG_8554.CR3.jpg';
import foto3 from '../img/IMG_8544.CR3.jpg';
import foto4 from '../img/IMG_8517.CR3 (1).jpg';
import foto5 from '../img/IMG_8511.CR3 (1).jpg';
import foto6 from '../img/IMG_1718 (3).jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './Carrossel.css';


export default function Carrossel(){
    
    const settings = {
        infinite: true,
        slidesToShow: 3, // Mostra 3 slides (o principal no centro e dois previews laterais)
        slidesToScroll: 1,
        centerMode: true, // Para que a imagem central fique maior
        centerPadding: "0", // Remove espaçamento nas laterais
        focusOnSelect: true, // Seleciona slide ao clicar no preview lateral
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    };

    return(
        <>
             <div className="carousel-container mt-4">
                <Slider {...settings}>
                    <div>
                        <img src={foto1} className="carousel-image" alt="Imagem 1 da aula" />
                    </div>
                    <div>
                        <img src={foto2} className="carousel-image" alt="Imagem 2 da aula" />
                    </div>
                    <div>
                        <img src={foto3} className="carousel-image" alt="Imagem 3 da aula" />
                    </div>
                    <div>
                        <img src={foto4} className="carousel-image" alt="Imagem 3 da aula" />
                    </div>
                    <div>
                        <img src={foto5} className="carousel-image" alt="Imagem 3 da aula" />
                    </div>
                    <div>
                        <img src={foto6} className="carousel-image" alt="Imagem 3 da aula" />
                    </div>
                    
                </Slider>
            </div>
        
        </>
    );
}