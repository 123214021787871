import React from "react";
import './Professores.css';
import { Card, CardGroup, Carousel  } from 'react-bootstrap';
import prof1 from '../img/IMG_1192.jpg';
import prof2 from '../img/IMG_7953.jpg';
import prof3 from '../img/IMG_1150.jpg';
import prof4 from '../img/IMG_7599[1].jpg';
import prof5 from '../img/IMG_2305.jpg';
import prof6 from '../img/Cópia de IMG_7562.jpg';
import prof7 from '../img/IMG_0492.jpg';
import prof8 from '../img/IMG_1491.JPG';
import prof9 from '../img/IMG_2768.jpg';
import prof10 from '../img/MNG01253.jpg';
import NavBar from '../components/NavBar';


function Professores(){
    const professores = [
        { nome: 'Pr. Vitor Ledo', foto: prof1 },
        { nome: 'Pra. Mayra Ledo', foto: prof2 },
        { nome: 'Pr. Lourenço Ledo', foto: prof3 },
        { nome: 'Pra. Márcia Ledo', foto: prof4 },
        { nome: 'Lucas Urrutty', foto: prof5 },
        { nome: 'Verena Urrutty', foto: prof6 },
        { nome: 'Renan Amaral', foto: prof7 },
        { nome: 'Lorena Amaral', foto: prof8 },
        { nome: 'Ingrid Souza', foto: prof9 },
        { nome: 'Ana Luiza Marques', foto: prof10 },
    ];
    return(
        <>
            <NavBar />

            <div className="curso-content4">
            <div className="curso-header4">
                    <h1 className="curso-title4">Conheça nossos professores</h1>
                </div>
                <p className="curso-description4">
                Líderes e convidados capacitados e treinados para compartilhar revelações, vida de deus e jornada com você durante nossos cursos
                </p>
            </div>
            <div className="footer-line-curso4">
            <div className="content-modulo4">
            <CardGroup className="professor-card-group">
                    {professores.map((prof, index) => (
                        <Card key={index} className="professor-card">
                            <Card.Body>
                                <Card.Title className="professor-name">{prof.nome}</Card.Title>
                                <Card.Img variant="bottom"className="professor-img" src={prof.foto} />
                            </Card.Body>
                        </Card>
                    ))}
                </CardGroup>
                <Carousel className=" carousel-professores mobile-only">
                        {professores.map((prof, index) => (
                            <Carousel.Item key={index}>
                                <Card className="professor-card">
                                    <Card.Body>
                                        <Card.Title className="professor-name">{prof.nome}</Card.Title>
                                        <Card.Img variant="bottom" className="professor-img" src={prof.foto} />
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div> 
            </div>
            <div className="footer-line31">
                <div className="contact-info2">
                    <span className="ctt24">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                            </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
                   

        </>
    );
}

export default Professores;